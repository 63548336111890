body {
  font-family: Gilroy;
}

.App {
  display: flex;
  justify-content: center;
}

.borderTopWhite {
  border-top: 1px solid black;
}

.borderBottomGray {
  border-bottom: 1px solid black;
}

.borderTopGray {
  border-top: 1px solid black;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.filter_hide {
  max-height: 0;
  overflow: hidden;

  /* Set our transitions up. */
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  transition: max-height 0.5s;
}

.filter_show {
  /* On hover, set the max-height to something large. In this case there's an obvious limit. */
  max-height: 400px;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: visible;
}

.app-container {
  width: 80%;
  max-width: 1440px;
}

.sub-heading-wrapper {
  display: flex;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.info-icon {
  border: 2px solid #c5cee0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: #c5cee0;
  }

  margin-left: 10px;
}

.section-info-icon {
  color: #c5cee0;
}

.main-app-grid {
  column-gap: 60px;
}

@media only screen and (max-width: 600px) {
  .app-container {
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 95%;
  }
}

.box-btn-wrapper {
  display: flex;
  gap: 24px;
  margin: 20px 0;

  .box-btn {
    transition-duration: 200ms;
    padding: 15px 40px;
    background: #ffffff;
    border: 1px solid #38a3a5;
    border-radius: 8px;
    flex-basis: 135px;
    flex-grow: 0;
    flex-shrink: 0;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #38a3a5;

    white-space: nowrap;
  }
}

.landing-calculator {
  .chart-div {
    padding: 16px;
    margin-top: 40px;
    border: 1px solid #cdeaea;
    border-radius: 16px;
    background-color: white;
  }

  .roas-chart-div {
    margin-top: 0;
  }

  .sub-heading {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    text-transform: capitalize;
    color: #082d45;
  }

  .chart-legends .legend {
    margin-bottom: 0;
  }

  .get-started-btn {
    width: 100%;
    padding: 20px 42px 20px 40px;
    background: #082d45;
    box-shadow: 0px 4px 33px rgba(87, 204, 153, 0.3);
    border-radius: 8px;

    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: white;

    margin-top: 34px;
  }

  .chart-legends {
    gap: 16px;
  }

  .input-card {
    border: 1px solid #cdeaea;
    border-radius: 16px;
    height: 100%;

    .email-input-label {
      margin: 10px 0;
    }

    .email-input-wrapper {
      margin-top: 0px;
    }

    .input-section {
      margin-top: 30px;
    }

    .confirm-email-wrapper {
      margin-top: 30px;
    }

    .section-heading-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 35px;
      color: #082d45;
    }
  }

  .hor-bar-chart-wrapper {
    height: 300px;
  }

  .chart-info-icons {
    display: none;
  }

  .area-chart-wrapper {
    height: 300px;
  }

  .landing-space {
    .chart-div:not(.roas-chart-div) {
      margin-top: 100px;
    }
  }

  .roas-chart-div {
    .area-chart-overlays {
      z-index: 0;
      height: 93%;
    }

    canvas {
      position: relative;
      z-index: 1;
    }
  }

  .section-info-div {
    z-index: 2;
  }

  .stack-bar-chart {
    z-index: 0;

    canvas {
      position: relative;
      z-index: 1;
    }

    .hor-bar-chart-overlays {
      .status-quo-overlay {
        height: 85px;
      }

      .rr-man-overlay {
        height: 150px;
      }
    }
  }

  @media only screen and (width > 1550px) {
    .input-card {
      padding-bottom: 55px;
    }
  }

  // For Ad Performance Chart
  @media only screen and (width < 750px) {
    .roas-chart-div {
      margin-top: 20px;
    }

    .hor-bar-chart-wrapper {
      height: 350px;
    }

    .chart-div {
      padding: 8px;
    }

    .sub-heading {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
    }

    .input-card {
      p.tell-about-business {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
      }

      .section-heading-text {
        font-weight: 700;
        font-size: 15px;
        line-height: 25px;
      }

      .email-input {
        margin-top: 0px;
      }

      .input-section {
        p:not(.section-heading-text) {
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }

    .area-chart-wrapper {
      height: 130px;

      .area-chart-overlays {
        height: 110px;
        margin-left: 2px;

        div p {
          font-weight: 600;
          font-size: 10px;
          line-height: 11px;
        }
      }
    }

    .chart-legends {
      flex-direction: row;
      column-gap: 20px;
      row-gap: 10px;

      .legend {
        font-size: 10px;
        line-height: 12px;
        margin: 0;
      }
    }

    .stack-bar-chart {
      .hor-bar-chart-wrapper {
        height: 210px;
      }

      .chart-bottom-line {
        bottom: 15%;
      }

      .hor-bar-chart-overlays {
        .status-quo-overlay {
          height: 60px;
          display: flex;
          align-items: center;
        }

        .rr-man-overlay {
          height: 105px;
          display: flex;
          align-items: center;
        }

        p {
          font-size: 10px;
          line-height: 14px;
          width: 60px;
        }

        p.mobile-caption {
          display: none;
        }
      }
    }
  }
}

.landing-calculator.v2-landing {
  .input-card.v2-landing {
    background: #ffffff;
    /* Revenue Green */

    border: 2px solid #57cc99;
    border-radius: 16px;

    box-shadow: unset;

    .tell-about-business {
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      text-transform: capitalize;
      color: #22577a;
      text-align: center;
    }

    .section-heading-text {
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #38a3a5;
      margin-top: 8px;
      margin-bottom: 0;
    }

    .start-growing-text {
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      text-align: center;
      text-transform: capitalize;
      color: #22577a;
    }

    .start-growing-v2 {
      margin-top: 40px;

      .get-started-btn {
        padding: 15px 40px;
        background: #57cc99;
        border-radius: 8px;
        box-shadow: unset;
        margin-top: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .v2-landing-charts {
    .chart-div.roas-chart-div {
      padding-top: 0;
      margin-top: 0;
    }

    .chart-div {
      border: unset;
      margin-top: 40px;
      padding-bottom: 0;

      .info-icon {
        display: none;
      }

      .sub-heading {
        font-weight: 700;
        font-size: 25px;
        line-height: 35px;
        text-transform: capitalize;
        color: #22577a;
      }

      .area-chart-wrapper {
        height: 240px;

        .area-chart-overlays {
          height: 91%;
          margin-left: 2px;
          margin-right: 2px;
        }
      }

      .stack-bar-chart {
        .hor-bar-chart-wrapper {
          height: 270px;

          .chart-bottom-line {
            bottom: 11%;
          }
        }

        .chart-legends {
          flex-direction: row-reverse;
          padding-left: 0;
        }

        .hide-caption {
          position: absolute;
          right: 0;
          height: 75px;
          width: 110px;
          pointer-events: none;
          background-color: #fafdfd;
          z-index: 2;
          top: 8%;
        }

        .hor-bar-chart-overlays {
          top: 8%;

          .status-quo-overlay {
            height: 75px;
          }

          .p.mobile-caption {
            display: block;
          }

          .rr-man-overlay {
            height: 130px;
          }

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #22577a;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 749px) {
    .input-card.v2-landing {
      .tell-about-business {
        font-size: 20px;
        line-height: 30px;
      }

      .section-heading-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }

      .input-section p.input-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      .start-growing-v2 {
        margin-top: 40px;

        .get-started-btn {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .v2-landing-charts {
      .chart-div.roas-chart-div {
        margin-top: 40px;

        .chart-legends {
          flex-direction: column;

          .legend {
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #151a30;
            }
          }
        }
      }

      .chart-div {
        padding-left: 0;
        padding-right: 0;

        .sub-heading {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
        }

        .area-chart-wrapper {
          height: 160px;
          margin-top: 20px;

          .area-chart-overlays {
            height: 88%;
            margin-left: 3px;
            width: 99%;

            p {
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
            }
          }
        }

        .stack-bar-chart {
          .hor-bar-chart-wrapper {
            height: 260px;
          }

          .hide-caption {
            display: none;
          }

          .chart-legends {
            flex-direction: column-reverse;

            .legend {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #151a30;
            }
          }

          .hor-bar-chart-overlays {
            top: 3%;

            .status-quo-overlay {
              display: block;
              height: 67px;
              padding-left: 8px;
            }

            .rr-man-overlay {
              display: block;
              height: 140px;
              padding-top: 3px;
              padding-left: 8px;
            }

            p {
              width: unset;
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
              color: #22577a;
            }

            p.mobile-caption {
              display: block;
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              color: #22577a;
            }

            p.mobile-caption.imp-roas {
              margin-top: 35px;
            }
          }
        }
      }
    }
  }
}

.number-box {
  transition-duration: 200ms;
  background: #ffffff;
  box-shadow: 3px 4px 17px rgba(56, 163, 165, 0.14);
  border-radius: 16px;
  align-items: center;
  padding: 8px 12px;
  text-align: center;
  width: 100%;
  flex-grow: 1;

  .box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.8px;
    color: #082d45;

    span {
      vertical-align: text-bottom;
    }
  }

  .box-value {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #38a3a5;
    vertical-align: unset;
  }
}

.box-btn-wrapper:hover {
  transition-duration: 200ms;

  .box-btn {
    background-color: #80ed991a;
  }

  .box-btn:hover {
    transition-duration: 200ms;
    background-color: #38a3a5;
    color: white;
  }

  .number-box {
    background-color: #57cc99;

    p {
      color: white;
    }

    p.box-value {
      color: white;
    }
  }
}

.group-bar-chart.box-btn-wrapper {
  height: unset;
  margin-bottom: 0px;
}

.input-card {
  background-color: white;
  padding: 32px;
  border-radius: 32px;
  box-shadow: 7px 10px 51px rgba(56, 163, 165, 0.2);
  height: fit-content;

  .tell-about-business {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    text-transform: capitalize;
    color: #082d45;
    margin-bottom: -10px;
  }

  .email-input-wrapper {
    margin-top: 20px;
    width: 100%;
    background: #f7fef8;
    padding: 8px;
    border-bottom: 2px solid #38a3a5;

    .email-input {
      background-color: inherit;
      width: 100%;
    }

    .email-input:focus {
      outline: none;
    }
  }

  .download-btn.confirm-email-btn {
    margin-top: 20px;
  }

  .disclaimer-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #082d45;
    opacity: 0.7;
    text-align: center;
    font-style: italic;
    margin-top: 16px;
  }

  .download-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 40px;
    gap: 16px;
    width: 100%;
    border: 2px solid #38a3a5;
    border-radius: 8px;
    height: 54px;

    margin-top: 10px;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #38a3a5;
  }

  .download-btn:hover {
    transition-duration: 200ms;
    background-color: #38a3a5;
    color: white;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(172deg) brightness(104%)
        contrast(101%);
    }
  }

  .download-btn.saving {
    transition-duration: 200ms;
    background: #80ed991a;
    cursor: not-allowed;

    div {
      margin: 0 !important;
    }
  }

  .download-btn.saving:hover {
    color: #38a3a5;
  }
}

.input-field {
  background: #ffffff;
  border: 1px solid #c5cee0;
  border-radius: 8px;
  padding: 12px 16px;

  input {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Brand/Ocean Blue */

    color: #22577a;
  }
}

.input-field-disabled {
  border-bottom: unset;
  background: white;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #22577a;
    background: inherit;
    padding: 0;
  }
}

.main-heading {
  margin-bottom: 48px;
  margin-top: 45px;
  font-size: 50px;
  line-height: 65px;
  color: #082d45;
  text-align: center;
  font-weight: 700;
}

.section-info-div:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  left: 100px;
  border: 11px solid transparent;
  border-bottom-color: white;
}

#chartjs-tooltip::before {
  content: "";
  display: block;
  position: absolute;
  border: 11px solid transparent;
  width: 0;
  height: 0;
}

#chartjs-tooltip.left::before {
  top: 50%;
  border-right-color: white;
  z-index: -1;
}

#chartjs-tooltip.right::before {
  top: 50%;
  border-left-color: white;
  z-index: -1;
}

#chartjs-tooltip.top::before {
  bottom: 100%;
  border-bottom-color: white;
}

#chartjs-tooltip.bottom::before {
  top: 100%;
  border-top-color: white;
}

#chartjs-tooltip {
  z-index: 2;
}

.section-info-div {
  position: absolute;
  padding: 12px;
  width: 240px;
  background: #ffffff;
  border-radius: 8px;
  top: 30px;
  left: -100px;
  z-index: 1;
  filter: drop-shadow(10px 11px 41px rgba(67, 77, 109, 0.14));

  ul {
    padding-left: 20px;
  }

  li,
  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    list-style: disc;
    margin-bottom: 10px;
  }

  li:last-child {
    margin-bottom: 0px;
  }

  p {
    margin: 0;
  }
}

.input-section {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #151a30;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .input-row {
    gap: 16px;
  }
}

.details-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .details-btn {
    padding: 9px 40px;
    background: #ffffff;
    border: 2px solid #22577a;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: "ordn" on;
    color: #22577a;

    width: 220px;
  }
}

.section-heading-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  p.section-heading-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #22577a;

    margin: 0;
  }

  .info-icon {
    margin: 0;
  }

  .react-tooltip {
    /* Brand/Deep Ocean Blue */

    background: #082d45;
    border-radius: 8px;
    opacity: 1;

    padding: 6px 10px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    width: 200px;

    z-index: 2;

    span {
      color: white;
    }
  }
}

.input-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #22577a;
}

.pill-group {
  display: flex;
  padding: 4px;
  gap: 8px;
  background: #eefaf5;
  border-radius: 24px;
  width: fit-content;

  cursor: pointer;
  position: relative;

  .bg {
    position: absolute;
    background-color: #38a3a5;
    height: 80%;
    border-radius: 24px;
  }

  .single-pill {
    padding: 4px 12px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #38a3a5;
    border-radius: 24px;
    z-index: 1;

    transition-duration: 500ms;

    &.selected {
      color: white;
      transition-duration: 500ms;
    }
  }
}

.sub-heading {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  text-transform: capitalize;
  color: #082d45;
}

.table-div {
  margin-top: 24px;
  padding: 40px 0px;
}

.table-div .sub-heading {
  margin-bottom: 24px;
}

.chart-div {
  margin-top: 35px;
  width: 100%;
}

.roas-chart-div {
  margin-top: 20px;
}

.group-chart-div {
  margin-top: 40px;

  .chart-legends {
    padding-left: 12%;
  }
}

.difference-component {
  margin-top: 70px;

  .sub-heading {
    margin-bottom: 32px;
  }

  .differences {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    position: relative;

    .difference-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      gap: 12px;

      border: 1px solid #cdeaea;

      box-shadow: 0px 11px 19px rgba(143, 155, 179, 0.1);
      border-radius: 16px;
    }

    .difference-text {
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #22577a;
        text-align: center;

        span {
          font-weight: 700;
          color: #57cc99;
        }
      }
    }
  }
}

.area-chart-overlays {
  position: absolute;
  display: flex;
  width: 100%;
  height: 94%;
  z-index: -1;

  div {
    padding: 8px;
    position: absolute;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #22577a;
    }
  }

  .ac-st-quo-overlay {
    height: 100%;
    width: 33.5%;
    height: 100%;
    left: 0.3%;
    background: #fafdfd;
  }

  .ac-st-quo-overlay.hovered.hv-st-quo {
    background-color: #80ed99;
  }

  .ac-rr-man-overlay {
    left: 33.5%;
    height: 100%;
    width: 65.9%;
    height: 100%;
    background: #f3f9f9;
  }

  .hovered {
    p {
      color: white;
    }

    .hover-text-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      min-height: 16px;
    }

    .hover-text-value {
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      animation-duration: 200ms;
    }
  }

  .ac-rr-man-overlay.hovered {
    background: #57cc99;
  }

  .ac-rr-man-overlay.hovered.hv-marketing {
    background-color: #38a3a5;
  }
}

.hor-bar-chart-overlays {
  position: absolute;
  width: 100%;
  display: flex;
  top: 7%;
  flex-direction: column;
  z-index: -1;

  .mobile-caption {
    display: none;
  }

  .status-quo-overlay {
    height: 75px;
    background: #fafdfd;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    z-index: -1;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .rr-man-overlay {
    height: 160px;
    background: #f3f9f9;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    z-index: -1;

    p {
      width: 86px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.gc-overlays {
  position: absolute;
  width: 100%;
  height: 76%;
  left: 80px;
  top: 45px;
  font-size: 14px;
  font-family: "Gilroy";
  font-weight: 600;
  z-index: -1;
  display: flex;

  p {
    color: #22577a;
  }

  div {
    height: 100%;
    padding: 8px;
  }

  .gc-st-quo-overlay {
    width: 9%;
    background: #fafdfd;
    text-align: center;
  }

  .gc-st-quo-overlay.borrowed {
    width: 15%;
  }

  .gc-rr-man-overlay.borrowed {
    width: 75%;
  }

  .gc-rr-man-overlay {
    left: 9%;
    background: #f3f9f9;
    width: 81%;
  }

  .gc-be-overlay.cash {
    right: 10%;
    width: 36%;
    background: #e8f4f4;
    top: 7%;
    height: 93%;
    position: absolute;
    transition-duration: 3000ms;
  }

  .gc-be-overlay.borrowed {
    transition-duration: 300ms;
    left: 32%;
    width: 58%;
    background: #e8f4f4;
    top: 10%;
    height: 90%;
    position: absolute;
  }
}

.ras-bar-overlays {
  position: absolute;
  width: 99%;
  display: flex;
  left: 5.2%;
  height: 89%;
  z-index: -1;

  div {
    padding: 11px 8px;
    font-weight: bold;
    font-size: 14px;

    p {
      color: #22577a;
    }
  }

  .ras-st-quo-overlay {
    width: 16%;
    background: #fafdfd;
  }

  .ras-rr-man-overlay {
    width: 80%;
    background: #f3f9f9;
  }
}

.area-chart-wrapper {
  margin-top: 30px;
  height: 320px;
}

.hor-bar-chart-wrapper {
  height: 300px;
  position: relative;

  .chart-bottom-line {
    width: 90%;
    border-bottom: 1px solid #38a3a524;
    position: absolute;
    bottom: 10%;
    right: 0;
  }

  .chart-info-icons {
    position: absolute;
    right: 10px;
    top: 135px;

    .group {
      margin-bottom: 65px;
    }
  }
}

.group-bar-chart-wrapper {
  position: relative;
  height: 520px;
  overflow-x: hidden;
}

.chart-legends {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .legend {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    .color-box {
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color: grey;
      margin-right: 12px;
    }

    p {
      pointer-events: none;
    }
  }
}

.ras-bar-chart {
  .section-heading {
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
  }

  .section-subheading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    color: #151a30;
    margin-bottom: 28px;
  }

  .main-caption {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #082d45;
  }

  .ras-input {
    width: 79px;
    height: 32px;

    padding: 4px 8px;
    background: #ffffff;
    border: 1px solid #c5cee0;
    border-radius: 8px;

    input {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #22577a;
    }
  }

  .min-per {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #151a30;
    opacity: 0.5;
  }

  .small-note {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #22577a;
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .chart-wrapper {
    height: 750px;
    position: relative;
  }

  .chart-legends {
    .legend {
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.app-table {
  font-weight: 400;

  th.p-0,
  td.p-0 {
    padding: 0;
  }

  table {
    width: 95%;
    border-collapse: separate !important;
    border-spacing: 0;
    border-right: 1px solid #cdeaea;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom: 1px solid #cdeaea;
    border-bottom-left-radius: 20px;
  }

  .table-last-cell {
    border-bottom-right-radius: 20px;
  }

  .title-row {
    padding: 25px 0px;
    margin-top: 32px;

    .table-category {
      border-top: 1px solid #cdeaea;
      border-left: 1px solid #cdeaea;
      border-top-left-radius: 20px;
      border-bottom: none;
    }

    td {
      border-top: 1px solid #cdeaea;
    }
  }

  thead {
    th.empty-space {
      border: none;
    }

    th.title-cell {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;

      span {
        font-weight: 700;
      }

      &.main-roas-title {
        vertical-align: middle;
        line-height: 18px;
      }

      &.imp-roas-main-header {
        line-height: 18px;
      }
    }

    th {
      vertical-align: top;
      color: #22577a;
      font-weight: 600;
      font-size: 12px;
      padding: 16px;

      span {
        color: #22577a;
      }
    }

    .st-quo-header {
      border: 1px solid #cdeaea;
      border-top-left-radius: 20px;
      border-bottom: 0;
      padding: 16px;
    }

    .rev-man-header {
      border-top: 1px solid #cdeaea;
      border-top-right-radius: 20px;
    }

    .main-roas-title {
      padding: 0;
    }

    .imp-roas-main-header {
      padding: 0;
      padding-right: 16px;
    }

    span.improved-roas-main-header {
      width: 100%;
      height: 30px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  tr {
    border-right: 1px solid #cdeaea;
  }

  td,
  th {
    // border: inherit;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-color: inherit;
    padding: 6px 16px;
  }

  .th-border {
    height: 24px;
    position: absolute;
    border-left: 1px solid #cdeaea;
    bottom: 10px;
    margin-left: -10px;
  }

  .cell-border {
    position: absolute;
    height: 80%;
    margin-left: -10px;
    bottom: 18px;
    border-left: 1px solid #cdeaea;
  }

  .cell-border.body1 {
    bottom: 18px;
    height: 292px;
  }

  .cell-border.body0 {
    bottom: 48px;
    height: 202px;
  }

  td {
    border: none;
    border: 0px solid #cdeaea;
  }

  .improved-roas-color {
    background-color: #f3f9f9;
  }

  .table-cell.improved-roas-color.last-column {
    border-right: 16px solid #fafdfd;
  }

  .same-roas {
    background-color: #fafdfd;
  }

  .st-quo {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .table-category {
    color: #38a3a5;
    font-weight: 700;
    padding-top: 24px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  .rowName {
    text-align: left;
    border: none;
    border-left: #cdeaea 1px solid;
    color: #082d45;
  }

  .total-row {
    background: #fafdfd;
    font-weight: 600;

    td {
      border-top: 1px solid #cdeaea;
      border-bottom: 1px solid #cdeaea;
    }
  }

  .total-row + tr {
    td {
      padding-top: 18px;
    }
  }

  .last-row {
    td {
      padding-bottom: 18px;
    }

    .rowName {
      border-bottom-left-radius: 20px;
    }
  }

  .empty-row {
    height: 32px;

    .rowName {
      border: none;
    }
  }

  &.safari {
    .cell-border {
      display: none;
    }

    .th-border {
      display: none;
    }

    tr {
      td.table-cell.td-border, th.table-cell.td-border {
        border-left: 1px solid #cdeaea;
      }
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.group-bar-chart {
  height: 503px;
}

@media screen and (width > 1439px) {
  .box-content {
    p {
      white-space: nowrap;
    }
  }
}

@media screen and (width < 1200px) {
  .main-app-grid {
    column-gap: 4%;
  }
}

// For Horizontal Chart Overlays
@media only screen and (width < 1201px) {
  .status-quo-overlay {
    top: 94px;
  }

  .rr-man-overlay {
    top: 169px;
  }
}

// For Ad Performance Chart
@media only screen and (width < 1236px) {
  .area-chart-overlays {
    height: 94%;
  }
}

// For Stats Boxes above charts
@media only screen and (width < 1024px) {
  .box-btn-wrapper {
    width: auto;
    flex-direction: column;

    .box-content {
      flex-direction: column;
    }

    .number-box {
      padding: 16px 40px;
      width: auto;
    }

    .box-btn {
      flex-basis: unset;
    }
  }
}

// Generic Mobile Styles
@media only screen and (width < 750px) {
  .input-card {
    padding: 24px 16px;
    border-radius: 24px;
  }

  .main-heading {
    margin-bottom: 20px;
    text-align: left;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }

  .table-scroller {
    max-width: 100%;
    overflow-x: scroll;
    scrollbar-width: 0;

    .app-table {
      max-width: 90vw;

      table {
        max-width: 90vw;
      }
    }

    .rowName {
      clear: both;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .table-scroller::-webkit-scrollbar {
    display: none;
  }

  .roas-chart-div {
    margin-top: 48px;
  }

  .gc-overlays {
    left: 17%;
    width: 90%;
    height: 62%;

    p {
      font-size: 12px;
    }

    .gc-rr-man-overlay.cash {
      width: 81%;
    }

    .gc-rr-man-overlay.cash {
      p {
        width: 74px;
      }
    }

    .gc-st-quo-overlay {
      width: 10%;

      p {
        display: none;
      }
    }

    .gc-st-quo-overlay.borrowed {
      width: 15%;
    }

    .gc-be-overlay.cash {
      right: 10%;
      width: 34.5%;
      height: 97%;
      top: 3%;
    }

    .gc-be-overlay.borrowed {
      left: 33%;
      width: 58%;
    }
  }

  .ras-bar-chart {
    .ras-bar-overlays {
      height: 87%;
      left: 10%;
      width: 94%;

      p {
        display: none;
      }
    }

    .ras-input.input-field {
      width: 60%;
      height: 32px;
    }
  }

  body {
    overflow-x: hidden;
  }

  .hor-bar-chart-wrapper {
    height: 350px;

    .chart-bottom-line {
      width: 100%;
      border-bottom: 1px solid #38a3a524;
      position: absolute;
      bottom: 9%;
      right: 0;
    }

    p.mobile-caption {
      display: block;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #22577a;
    }

    p.mobile-caption.imp-roas {
      margin-top: 70px;
    }

    .hor-bar-chart-overlays {
      top: 2%;
      width: 100%;
      align-items: flex-start;

      div {
        display: block;
        padding: 10px 20px;
      }

      .status-quo-overlay {
        height: 85px;
      }

      .rr-man-overlay {
        height: 200px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        width: unset;
      }
    }
  }

  .section-info-div {
    left: unset;
    right: 0;
  }

  .section-info-div:before {
    left: unset;
    right: 0;
  }

  .hor-bar-chart-wrapper {
    .chart-info-icons {
      top: 120px;
    }
  }

  .area-chart-overlays {
    .hovered .hover-text-title {
      width: 200px;
    }
  }

  .chart-legends {
    flex-direction: column;
  }

  .ras-st-quo-overlay {
    width: 18%;
    background: #fafdfd;
  }

  .ras-rr-man-overlay {
    width: 78%;
    background: #f3f9f9;
  }

  .group-chart-div {
    .chart-legends {
      padding-left: 15%;
    }
  }

  .differences {
    flex-direction: column;
  }
}

.full-calculator {
  .main-heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
  }

  .area-chart-wrapper {
    height: 280px;

    .area-chart-overlays {
      height: 92%;
    }
  }

  .stack-bar-chart {
    .chart-legends {
      flex-direction: row-reverse;
      padding-left: 0;
    }
  }

  .roas-chart-div {
    margin-top: unset;
  }

  .app-select {
    .select-option {
      .option-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .chart-div {
    .box-btn-wrapper {
      .box-btn {
        border: 2px solid #22577a;
        border-radius: 8px;
        color: #22577a;
      }

      .box-btn:hover {
        color: white;
        background-color: #57cc99;
        border-color: #57cc99;
      }
    }

    .chart-heading-wrapper {
      .group.relative {
        display: none;
      }
    }
    .hor-bar-chart-wrapper {
      .group.relative {
        .info-icon {
          display: none;
        }
      }
    }
  }

  .input-card {
    background: #ffffff;
    border: 2px solid #57cc99;
    border-radius: 16px;
    box-shadow: unset;

    display: flex;
    flex-direction: column;
    gap: 48px;

    .confirm-email-wrapper {
      .email-input {
        width: 100%;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #c5cee0;
        }
      }
    }

    .input-section {
      .input-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #22577a;

        margin-bottom: 8px;
      }

      &.client-type {
        .event-type-selector {
          margin-top: 16px;
        }
      }

      &.fin-method {
        .section-description {
          margin-top: 24px;
          font-weight: bold;
          color: #22577a;
        }
      }

      .section-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #082d45;

        margin-bottom: 16px;
      }
      .pill-group {
        width: 100%;
        justify-content: space-between;

        .single-pill {
          flex: 1;
          text-align: center;
        }
      }
    }

    .download-btn {
      background: #ffffff;
      border: 2px solid #22577a;
      border-radius: 8px;
      color: #22577a;

      img {
        filter: brightness(0) saturate(100%) invert(32%) sepia(14%) saturate(2175%) hue-rotate(161deg) brightness(89%)
          contrast(94%);
      }
    }

    .download-btn:hover {
      background-color: #22577a;
      color: white;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(107%)
          contrast(101%);
      }
    }
    .disclaimer-text {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 749px) {
  .full-calculator {
    .main-heading {
      font-size: 30px;
      line-height: 40px;
    }

    .input-card {
      .input-section {
        .flex.justify-between {
          gap: 10px;
        }

        .flex.justify-between.no-wrap-mobile {
          flex-direction: unset;
          overflow: hidden;
        }
      }

      .pill-group {
        .single-pill {
          padding: 4px 12px;
          white-space: nowrap;
          flex: 1;
        }
      }
    }

    .roas-chart-div {
      margin-top: 40px;
    }

    .chart-div {
      .area-chart-wrapper {
        height: 220px;
      }
      .area-chart-overlays {
        height: 90%;
      }

      .box-btn-wrapper {
        .box-btn:hover {
          color: white;
          background-color: #38a3a5;
        }
      }
    }
    .details-btn-wrapper {
      .details-btn {
        width: 100%;
      }
    }
  }
}
