@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* @apply bg-background; */
    -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-family: Gilroy ;
    color: #082d45;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
